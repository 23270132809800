<template>
  <div class="wrapper overview">
    <div class="white-box">
      <h3 v-if="isPreorder">{{ $t('overview-event-details') }}</h3>

      <div class="table" v-if="isPreorder">
        <div class="row" v-for="(row, key) in table1" :key="'t1-' + key">
          <div class="col">{{ row.label }}</div>
          <div class="col">{{ row.value }}</div>
        </div>
      </div>

      <h3 class="mt30 mb20" v-if="isPreorder">{{ $t('overview-title-preorder') }}</h3>
      <h3 class="mt30 mb20" v-else>{{ $t('overview-title-buffet') }}</h3>

      <div class="table">
        <div class="row" v-for="(row, key) in cartItems" :key="'t2-' + key">
          <div class="col">{{ row.title }}</div>
          <div class="col">
            <span v-if="row.quantity">{{ row.quantity }} db</span>
          </div>
          <div class="col">{{ $n(getItemGrossPrice(row) * row.quantity, 'currency') }}</div>
        </div>
        <div class="row grossPrice">
          <div class="col">{{ $t('overview-gross-price') }}:</div>
          <div class="col"></div>
          <div class="col">
            {{ $n(grossPrice, 'currency') }}
          </div>
        </div>
      </div>

      <p v-if="buffetOverloaded" class="error">
        {{ $t('buffet-overloaded') }}
      </p>

      <button :class="{ confirmBtn: true, disabled: buffetOverloaded }" @click="confirmOrder()">
        <span v-if="isPreorder">
          {{ $t('overview-btn-confirm-preorder') }}
        </span>
        <span v-else>
          {{ $t('overview-btn-confirm-order') }}
        </span>
      </button>
    </div>

    <vue-final-modal v-model="modalOpened">
      <div class="modal-product prepayment" v-if="buffet && buffet.prePaymentNote">
        <div class="close-btn">
          <div class="circle">
            <button @click="closeModal()">
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
          </div>
        </div>
        <div class="content">
          {{ $t('recup-warning-text') }}
        </div>
        <div class="bottom">
          <button @click="nextConfirmOrder()" class="yellowBtn">
            {{ $t('overview-btn-next') }}
          </button>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { VueFinalModal } from 'vue-final-modal';
import { saveCart, savePayment } from '../../functions/api/payment';
import { marked } from 'marked';
import { getBuffets } from '../../functions/api/content';

export default {
  components: { VueFinalModal },
  data() {
    return {
      table1: [],
      modalOpened: false,
    };
  },
  computed: {
    buffet() {
      return this.$store.state.buffets.find((data) => data.id == this.$store.state.cart.buffetId);
    },
    buffetOverloaded() {
      if (this.buffet && typeof this.buffet.concurrentOrdersCounter !== undefined) {
        return this.buffet.concurrentOrdersCounter >= this.buffet.maxConcurrentOrders;
      } else {
        return false;
      }
    },
    prePaymentNote() {
      let text = '';
      if (this.buffet && this.buffet.prePaymentNote && this.hasDrinkInCart) {
        text = marked.parse(this.buffet.prePaymentNote);
      }

      return text;
    },
    isPreorder() {
      return this.$store.state.orderType == 'preorder';
    },
    netPrice() {
      return this.$store.getters.totalPrice;
    },
    vatAmount() {
      return Math.round(this.grossPrice - this.netPrice);
    },
    grossPrice() {
      return this.$store.getters.totalPrice;
    },
    cart() {
      return this.$store.state.cart;
    },
    cartItems() {
      let items = [];

      if (this.cart.items) {
        items = this.cart.items;
      }

      return items;
    },
    userToken() {
      return this.$store.state['mvmId'];
    },
    hasDrinkInCart() {
      return this.$store.state.cart.items.filter((item) => item.type == 'drink').length > 0;
    },
  },
  mounted() {
    this.$store.commit('setSkyboxTitle', 'overview-page-title');
    this.$store.commit('setSkyboxText', '');
    this.$store.commit('setSkyboxLink', 'history-back');
    this.$store.commit('setSkyboxLinkText', 'overview-select-products');

    this.loadEventDetails();
  },
  created() {
    if (this.buffetOverloaded) {
      setInterval(() => {
        getBuffets(this.$store.state.mvmId)
          .then((res) => {
            this.$store.commit('setBuffets', res);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
          });
      }, 30000);
    }
  },
  methods: {
    closeModal() {
      this.modalOpened = false;
    },
    confirmOrder() {
      if (this.buffet && this.buffet.prePaymentNote && this.hasDrinkInCart) {
        this.modalOpened = true;
      } else {
        this.storeOrder();
      }
    },
    nextConfirmOrder() {
      this.modalOpened = false;
      this.storeOrder();
    },
    storeOrder() {
      this.$store.commit('setLoading', true);
      const grossPrice = this.$store.getters['totalPrice'];
      let cart = this.cart;

      return saveCart(this.userToken, {
        ...cart,
        locale: this.$store.getters['getSavedLocale'],
      })
        .then((cart) => {
          return savePayment(this.userToken, cart.id)
            .then((payment) => {
              this.$store.commit('setLoading', false);

              this.$store.dispatch('postMessageCart', {
                orderId: payment.foodOrderId,
                grossPrice: grossPrice,
                eventName: this.$store.getters['getStoredEventName'],
              });

              if (payment.status === 'payment-failed') {
                throw payment;
              }

              return payment;
            })
            .catch((err) => {
              console.log(err);
              this.$store.commit('setLoading', false);
            });
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('setLoading', false);
        });
    },
    loadEventDetails() {
      let event = this.$store.getters['getStoredEvent'];

      let tableData = [];

      if (this.isPreorder && event) {
        tableData.push({
          label: this.$t('event-text') + ':',
          value: event.event.name,
        });
        tableData.push({
          label: this.$t('event-date') + ':',
          value: moment(event.event.date).format('YYYY. MMMM DD.'),
        });
      }

      this.table1 = tableData;
    },
    getItemGrossPrice(product) {
      return product.price;
    },
  },
};
</script>
